import {writable} from 'svelte/store';
import {fromHexToRgb} from 'Utility/fromHexToRgb';
import type {WhiteLabeling} from 'Utility/investorFlow/entity';

const DEFAULT_COLOR = '#29a39b';

const createWhiteLabeling = () => {
  const [primary_r, primary_g, primary_b] = fromHexToRgb(DEFAULT_COLOR);
  const {subscribe, set, update} = writable<WhiteLabeling>({
    banner_image: {
      regular: '',
      full: '',
    },
    primary_logo: '',
    primary_color: DEFAULT_COLOR,
    primary_color_rgb: {
      primary_r,
      primary_g,
      primary_b,
    },
    custom_favicon: '',
  });

  return {
    subscribe,
    update,
    set: (value: WhiteLabeling) => {
      const primaryColor = value.primary_color || DEFAULT_COLOR;
      const [primary_r, primary_g, primary_b] = fromHexToRgb(primaryColor);

      set({
        ...value,
        primary_color: primaryColor,
        primary_color_rgb: {
          primary_r,
          primary_g,
          primary_b,
        },
      });
    },
  };
};

//Retrieved data
export const whiteLabeling = createWhiteLabeling();
